<template>
    <div class="home">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12 text-center text-lg-left">
                    <h1 v-if="account" class="home__title">{{ $t('Welcome, {name}', { name: fullName }) }}</h1>
                    <p class="home__text">{{ $t('Manage your personal, companies and security information for a better experience.') }}</p>
                </div>
                <div class="col-md-12 col-xl-8 mb-4">
                    <Card>
                        <template v-slot:card_title>
                            {{ $t('Available apps') }}
                        </template>
                        <div>
                            <p class="home__card-text text-start">
                                {{ $t('Here are some of the apps you can easily sign in with Accounts.') }}
                            </p>
                            <div class="row justify-content-between home__cards-container">
                                <a href="https://seller.ecart.com/" class="col-md-6 col-xl-3">
                                    <div class="home__accounts-container">
                                        <div class="home__logo-container bg-black" >
                                            <img src="@/assets/images/logos/ecart-seller-white.svg" alt="Tendencys" />
                                        </div>
                                        <h4><strong>Ecart seller</strong></h4>
                                        <p class="text-center">
                                            {{ $t('Create and manage your online store.') }}
                                        </p>
                                    </div>
                                </a>
                                <a href="https://ecartpay.com/" class="col-md-6 col-xl-3">
                                    <div class="home__accounts-container">
                                        <div class="home__logo-container home__bg-green">
                                            <img src="@/assets/images/logos/ecartpay-white.svg" alt="ecart-pay" />
                                        </div>
                                        <h4><strong>Ecart Pay</strong></h4>
                                        <p class="text-center">
                                            {{ $t('Create payment codes, send and receive money.') }}
                                        </p>
                                    </div>
                                </a>
                                <a href="https://returns.envia.com/" class="col-md-6 col-xl-3">
                                    <div class="home__accounts-container">
                                        <div class="home__logo-container home__bg-blue">
                                            <img src="@/assets/images/logos/returns-white.svg" alt="Tendencys" />
                                        </div>
                                        <h4><strong>Returns</strong></h4>
                                        <p class="text-center">
                                            {{ $t('Manage your store returns in one place.') }}
                                        </p>
                                    </div>
                                </a>
                                <a href="https://envia.com/" class="col-md-6 col-xl-3">
                                    <div class="home__accounts-container">
                                        <div class="home__logo-container home__bg-light-blue">
                                            <img src="@/assets/images/logos/envia.svg" alt="Tendencys" />
                                        </div>
                                        <h4><strong>Envia</strong></h4>
                                        <p class="text-center">
                                            {{ $t('Send your products to anywhere.') }}
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Card>
                </div>
                <div class="col-12 col-xl-4 mb-4">
                    <div class="home__card text-center d-flex flex-column justify-content-between">
                        <div>
                            <img src="@/assets/images/dashboard/home/personal-info.svg" class="home__card-icon img-fluid"
                                alt="Tendencys" />
                            <h5 class="home__card-title">{{ $t('Personal information') }}</h5>
                            <p class="home__card-text">
                                {{ $t('Check your account details.') }}
                            </p>
                        </div>
                        <router-link class="home__card-link" to="/dashboard/profile">
                            {{ $t('Manage your data') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Card from '@/components/ui/dashboard/Card.vue';

export default {
    name: "home",
    computed: {
        ...mapGetters("accounts", ["fullName"]),
        ...mapState("accounts", ["account"]),
    },
    components: { Card }
}
</script>

<style lang="scss">
.home {
    &__bg-blue {
        background-color: #002A60;
    }

    &__bg-light-blue {
        background-color: #dae9eb;
    }

    &__bg-green {
        background-color: #68AE34;
    }

    &__title {
        color: $general-black;
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: 5px;
    }

    &__text {
        color: $accounts-secondary-200;
        font-size: 1rem;
        margin-bottom: 35px;
    }

    &__card {
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0 0 8px rgba(#ACACAC, 0.05);
        padding: 20px;
    }

    &__cards-container {
        a {
            color: $general-black;

            &:hover {
                color: $general-black;
                text-decoration: none;
            }
        }
    }

    &__card-title {
        color: #141414;
        font-weight: 600;
        font-size: 16px;
    }

    &__card-text {
        font-size: 16px;
        color: #AAAAAA;
    }

    &__card-icon {
        height: 100px !important;
        margin-bottom: 15px;
        margin-top: 35px;
        width: auto;
    }

    &__card-link {
        margin-bottom: 25px;
        color: #438FFE;
        font-weight: 500;
        font-size: 16px;

        &:hover {
            color: #3476da;
            text-decoration: underline #3476da;
        }

    }

    &__accounts-container {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h4 {
            color: #000329;
            font-size: 1.3rem;
            text-align: center;
            width: 100%;
        }

        h6 {
            color: #787F84;
            font-size: 1rem;
        }
    }

    &__logo-container {
        width: 100px;
        height: 100px;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        & img {
            max-width: 100%;
            max-height: 100%;
        }
    }

}
</style>
