<template>
    <div class="card">
        <div class="card__top-content">
            <div class="card__top">
                <h5 class="card__top-title">
                    <slot name="card_title"></slot>
                </h5>
            </div>
            <div class="card__top-option">
                <slot name="card-actions"></slot>
            </div>
        </div>
        <div class="card__content">
            <slot></slot>
        </div>
        <slot name="card_items"></slot>
    </div>
</template>
<script>

export default {
    props: {
        items: {
            type: Array,
            default: null,
            required: false,
        }
    },
};
</script>

<style lang="scss" scoped>
.card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(#acacac, 0.05);
    padding: 45px;
    position: relative;

    @media(max-width:992px){
        padding: 25px 16px;
    }

    &--has-footer-link {
        padding-bottom: 65px;
    }

    &__title {
        color: #141414;
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 15px;
    }

    &__item-container {
        position: relative;

        &--separator {
            @media (min-width: 992px) {
                &::after {
                    background-color: rgba(#83879b, 0.1);
                    content: "";
                    height: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 1px;
                }
            }
        }
    }

    &__item-title {
        font-size: 1.3rem;
        font-weight: 500;
        color: #7e7e7e;
        width: 90px;
    }

    &__item-content {
        color: #aaaaaa;
        font-size: 1rem;
        word-break: break-all;
        width: calc(100% - 90px);
    }

    &__footer-link {
        align-items: center;
        color: #438ffe;
        display: flex;
        font-weight: 500;
        font-size: 1rem;
        border-radius: 0px 0px 10px 10px;
        border-top: 1px solid #eeeeee;
        bottom: 0;
        height: 50px;
        justify-content: space-between;
        left: 0;
        padding-left: 45px;
        padding-right: 45px;
        position: absolute;
        width: 100%;

        &:hover {
            color: #3476da;
            text-decoration: none;
        }
    }

    &__top-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    &__top-title {
        font-size: 1.3rem;
        font-weight: bold;
        color: #585858;
        margin: 0;
        margin-bottom: 10px;

    }


    &__top-option {
        font-size: 1rem;
        font-weight: bold;
        color: #001CBC;

        button {
            all: unset;
            cursor: pointer;
        }
    }

    &__items {
        display: flex;
        flex-direction: column;
        border: 1px solid #EEF1F8;
        border-radius: 10px;

    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0.94rem 1.56rem;

        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
        &:not(:last-child) {
            border-bottom: 1px solid #EEF1F8;
        }
    }
}
</style>
